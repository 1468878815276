<template>
    <van-popup v-if="show" round v-model="show">
        <div class="ocr-id-card">
            <div id="cover" class="cover">
                <div class="id-card-container">
                    <img v-if="cardSide == 'front'" src="@/assets/uploadimg/front.png"
                        style="width: 100%;height:100%" />
                    <img v-else src="@/assets/uploadimg/back.png" style="width: 100%;height:100%" />
                </div>
                <video ref="videoRef" class="media-video" autoplay playsinline></video>
            </div>
            <div class="footer-tip">
                请将证件放于框内拍摄
            </div>
            <div class="footer-btn">
                <van-button type="info" round @click="snapPhoto">确定</van-button>
            </div>
            <canvas id="mycanvas" class="card-canvas"></canvas>
        </div>
    </van-popup>
</template>

<script>
import { uploadImg } from "@/api/credit";
export default {
    data() {
        return {
            image_url: "", // 身份证url
            imageBase64: "", // 身份证照片 base64
            cardSide: "front", // 身份证正反面 front：身份证有照片的一面(人像面)back：身份证有国徽的一面(国徽面
            video: {},
            videoTrack: {},
            showContent: "",
            show: false
        };
    },
    methods: {
        open(cardSide) {
            this.cardSide = cardSide;
            this.show = true
            this.$nextTick(() => {
                this.openCamera()
            })
        },
        close() {
            if (this.videoTrack) {
                this.videoTrack.stop();
            }
            this.show = false
        },
        uploadFile(file) {
            let fd = new FormData();
            fd.append("file", file);
            uploadImg(fd)
                .then((res) => {
                    this.image_url = res.data[0].imgUrl
                    this.$emit("success", {
                        [this.cardSide]: this.image_url
                    })
                    this.close()
                })
                .catch(() => {
                    file.status = "failed";
                    file.message = "上传失败";
                });
        },

        // 调用摄像头
        openCamera() {
            // constraints: 指定请求的媒体类型和相对应的参数
            const constraints = {
                audio: false,
                video: {
                    width: 1150,
                    height: 768,
                    frameRate: { ideal: 60 }, // 视频流帧率
                    facingMode: "environment", // 后置摄像头
                },
            };
            //   this.showContent = JSON.stringify(window.navigator)
            //   console.log(this.showContent, '========',Object.keys(window.navigator), window.navigator.mediaDevices)
            // 兼容部分浏览器
            if (!navigator.mediaDevices) navigator.mediaDevices = {};
            // 一些浏览器部分支持 mediaDevices,不能直接给对象设置 getUserMedia
            // 因为这样可能会覆盖已有的属性,只会在没有getUserMedia属性的时候添加它。
            if (navigator.mediaDevices.getUserMedia === undefined) {
                navigator.mediaDevices.getUserMedia = function (constraints) {
                    // 首先，如果有getUserMedia的话，就获得它
                    const getUserMedia =
                        navigator.getUserMedia ||
                        navigator.webkitGetUserMedia ||
                        navigator.mozGetUserMedia ||
                        navigator.msGetUserMedia ||
                        navigator.oGetUserMedia;
                    if (!getUserMedia) {
                        return Promise.reject(
                            new Error("getUserMedia is not implemented in this browser")
                        );
                    }
                    // 否则，为老的navigator.getUserMedia方法包裹一个Promise
                    return new Promise(function (resolve, reject) {
                        getUserMedia.call(navigator, constraints, resolve, reject);
                    });
                };
            }
            // 获取视频流
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    this.videoTrack = stream.getVideoTracks()[0];
                    this.video = document.querySelector(".media-video");
                    if (this.video) {
                        this.video.srcObject = stream;
                        this.video.onloadedmetadata = () => {
                            this.video.play();
                        };
                    }
                })
                .catch(function (err) {
                    console.error(err);
                });
        },
        // 监控页面visibilitychange
        watchPageVisible() {
            console.log("----------------------1-------------");
            document.addEventListener("visibilitychange", () => {
                if (!document.hidden) {
                    console.log("-----------------------------------");
                    this.openCamera();
                } else {
                    if (this.video && this.video.srcObject) {
                        this.video.srcObject.getTracks().forEach((track) => track.stop());
                    }
                }
            });
        },
        // 获取视频的一帧作为图片转换为base64，调用接口识别身份证信息
        snapPhoto() {
            const canvas = document.querySelector("#mycanvas");
            const ctx = canvas.getContext("2d")
            canvas.width = this.video.videoHeight;
            canvas.height = this.video.videoWidth;

            ctx.translate(canvas.width/2,canvas.height/2);
            ctx.rotate(-Math.PI /2);
            // ctx.drawImage(img,-img.width/2,-img.height /2);
            ctx.drawImage(this.video, -this.video.videoWidth/2, -this.video.videoHeight/2);
            const imageBase64 = canvas.toDataURL("image/png", 0.6);
            this.uploadFile(this.base64ToFile(imageBase64))
            // this.image_url = imageBase64
        },
        base64ToFile(base64, fileName) {
            let arr = base64.split(",");
            // console.log(arr[0], "========arr[0].match(/:(.*?);/)", arr[0].match(/:(.\*?);/))
            // let mime = arr[0].match(/:(.\*?);/)[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], fileName, { type: "image/png" });
        },
    },
};
</script>
<style lang="less" scoped>
// .back-bg{
//     background: url("~@/assets/uploadimg/back.png") 0 0 no-repeat;
// }
.ocr-id-card {
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background: #fff;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    .cover {
        width: 100vw;
        height: 160vw;
        // height: 62.5vw;
        position: relative;
        top: 0;
        left: 0;
        z-index: 2001;
        border: 1px solid #000;

        .id-card-container {
            width: 350px;
            height: 560px;
            // height: 219px;
            // background: url("~@/assets/parttime-operator/ocr-border.png") 0 0 no-repeat;
            // border: 1px solid red;
            // background-size: 708px 460px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2004;
        }
    }

    .media-video {
        width: 100%;
        height: 100%;
        position: absolute;
        // border: 1px solid red;
        // top: -25px;
        left: 0;
    }

    .footer-tip {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
        // width: 312px;
        // height: 64px;
        // background: rgba(0, 0, 0, 0.5);
        // position: fixed;
        // bottom: 392px;
        // left: 50%;
        // transform: translateX(-50%);
        // z-index: 2003;
    }

    .footer-btn {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 50px;
    }

    .card-canvas {
        position: fixed;
        left: -9999px;
        top: -9999px;
        z-index: 0;
        backface-visibility: hidden;
        transform: translateZ(0);
    }
}
</style>
